











import Vue from 'vue'
import Component from 'vue-class-component'
import ListView from '@/components/list/ListView.vue'
import { appendSitePrefix } from '@/utils/routeUtils'

@Component({
  components: {
    ListView,
  },
})
export default class List extends Vue {
  headers = []
  routes = {}
  topActions = []
  rowActions = []

  mounted() {
    this.headers = [
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Email',
        value: 'email',
      },
      {
        text: 'Mobile',
        value: 'mobile',
      },
      {
        text: 'Landline',
        value: 'landline',
      },
      {
        text: 'City',
        value: 'city',
      },
      {
        text: 'Actions',
        value: 'actions',
      },
    ]

    this.rowActions = [
      {
        id: 'show',
        route: (item) => {
          return appendSitePrefix('/' + item.id + '/driver/item')
        },
      },
      {
        id: 'delete',
      },
    ]

    this.topActions = [
      {
        id: 'new',
        route: () => {
          return appendSitePrefix('/N0/driver/new')
        },
      },
    ]
  }
}
